import './bootstrap';
import '../css/app.css';
import 'primeicons/primeicons.css';

import { createApp, h, DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import PrimeVue from 'primevue/config';
import Lara from './presets/lara';
import Ripple from 'primevue/ripple';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import * as Sentry from '@sentry/vue';
import { initializeSentryRouting } from './sentryRouting';

const appName = import.meta.env.VITE_APP_NAME || 'Tippspiel';
const sentryDsn = import.meta.env.VITE_SENTRY_DSN_PUBLIC;

createInertiaApp({
  title: title => `${title} - ${appName}`,
  resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    if (sentryDsn && sentryDsn !== 'null') {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        environment: import.meta.env.VITE_APP_ENV,
        integrations: [
          Sentry.browserTracingIntegration({
            // disable automatic span creation
            instrumentNavigation: false,
            instrumentPageLoad: false,
          }),
        ],
      });
      initializeSentryRouting();
    }

    app
      .use(plugin)
      .use(PrimeVue, { unstyled: true, pt: Lara, ripple: true })
      .use(ConfirmationService)
      .use(ZiggyVue)
      .directive('ripple', Ripple)
      .directive('tooltip', Tooltip)
      .mount(el);

    return app;
  },
  progress: {
    color: '#4B5563',
  },
});
