import { router } from '@inertiajs/vue3';
import { route } from 'ziggy-js';
import * as Sentry from '@sentry/vue';

export function initializeSentryRouting() {
  // Uses https://inertiajs.com/events
  let name: string = route().current() as string;
  const client = Sentry.getClient();

  if (client) {
    Sentry.startBrowserTracingPageLoadSpan(client, { op: 'pageload', name });
  }

  router.on('before', () => {
    const newName = route().current() as string;
    if (client === undefined) {
      return;
    }

    if (newName !== name) {
      name = newName;
      Sentry.startBrowserTracingNavigationSpan(client, {
        op: 'navigation',
        name,
        attributes: {
          [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
        },
      });
    }
  });

  router.on('finish', () => {
    name = route().current() as string;
    Sentry.getActiveSpan()?.updateName(name);
  });
}
